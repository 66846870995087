import _ from 'underscore'
import moment from "moment";

import HeadNav from '../../components/head-nav/index.vue';
import OrderLog from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/order-log/1.0.0/index.vue'
import ApprovalProcess from "@/component/approval-process/1.0.0/index.vue"
import consumer_air_ticket_ApprovalMethod from '@/lib/data-service/haolv-default/consumer_air_ticket_ApprovalMethod'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息

// 订单详情
import req_detail_data from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_queryHotelOrderDetails";
// 取消订单
import req_cancel_order from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelOrder";
import HotelRefund from "@/component/hotelRefund/index.vue";

export default {
  data() {
    return {
      loading: false,
      isShowBtns: true,
      activeName: 'detail',
      detail: {
        order: {
          hotelPicUrl: ''
        },
        personList: [],
        hoteltrainsOrderTipsResponses: [],
        recordList: [],
      },
      milliseconds: 0,
      paySetIntervalFun: '',
      approvalMethod: 1, // 1.我的申请 2.待我审批
      userInfo: {},//当前登录人信息
    };
  },
  computed: {},
  components: {
      HotelRefund,
    HeadNav,
    OrderLog,
    ApprovalProcess
  },
  methods: {
    req_detail() {
      this.loading = true;
      req_detail_data({ orderNo: this.$route.query.orderNo })
        .then((res) => {
          this.detail = {
            applyNo: res.applyNo,
            applyId: res.applyId,
            approveType: res.approveType,
            applicationId: res.applicationid,
            approveApply: res.approveApply,
            initialUserId: res.initialUserId,
            initialName: res.initialName,
            gmtCreate: res.gmtCreate,
            timeoutApproval: res.timeoutApproval,
            order: res.orderInterimResult,
            personList: res.hotelOrderPersonList ? res.hotelOrderPersonList : [],
            evectionInfo: res.tusEvection,
            approvalReason: res.approvalReason,
            travelFeeAffiliationMsgList: res.travelFeeAffiliationMsgList || [],
          };
          this.detail.order.isBeforeCheckIn = moment().add(-1, 'd').isBefore(this.detail.order.checkInDate, 'day');
          this.detail.order.nightlyRateList = res.hotelCostInfoResponse.roomViewList;
          this.detail.order.paymentChanelText = this.detail.order.paymentChanelText ? this.detail.order.paymentChanelText : '---';
          this.detail.order.hotelGroup = res.hotelGroup;
          this.detail.order.ifAgreement = res.ifAgreement;
          this.detail.order.cardNumber = res.cardNumber;
          this.detail.order.agreementHotelText = res.agreementHotelText;
          this.detail.order.serviceFee = res.hotelCostInfoResponse.serviceFee;
          this.detail.order.oneselfAmount = res.hotelCostInfoResponse.oneselfAmount; // 个人支付金额
          this.detail.order.creditAmount = res.hotelCostInfoResponse.creditAmount; // 企业支付金额
          this.detail.isExceed = res.showOrNot;
          this.detail.hoteltrainsOrderTipsResponses = res.hoteltrainsOrderTipsResponses ? res.hoteltrainsOrderTipsResponses : []
          if(this.detail.isExceed) {
            this.detail.recordList = res.recordList ? res.recordList : []
          }

          this.detail.milliseconds = res.milliseconds;
          this.milliseconds = this.detail.milliseconds || 0;
          if (this.milliseconds > 0) {
            this.paySetIntervalFun = setInterval(() => {
              this.milliseconds--;
              if (this.milliseconds <= 0) {
                clearInterval(this.paySetIntervalFun)
                this.req_detail()
              }
            }, 1000)
          }

          this.$refs.approvalProcess.init({
            status: res.status,
            statusText: res.statusText,
            approvalData: this.detail.recordList,
            approvalReason: res.approvalReason,
            btnParams: {
              isShow: false,
              approvalParams: {}
            }
          })

          if (this.detail.applyNo) {
            consumer_air_ticket_ApprovalMethod({applyNo: this.detail.applyNo}).then(res => {
              this.approvalMethod = res.datas.approvalMethod
            })
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async getCurUserInfo() {
      let res = await get_user_info();
      this.userInfo = res.datas;
    },
    // 订单状态
    getStatusColor(val) {
      if (val === 0 || val === 2 || val === 18) {
        return 'orange'
      } else if (val === 11) {
        return 'green'
      } else {
        return 'red'
      }
    },
    getRecordStatus(val) {
      // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
      if (val === 0) {
        return 'wait'
      } else if (val === 1) {
        return 'wait'
      } else if (val === 2) {
        return 'pass'
      } else if (val === 3 || val === 4) {
        return 'fail'
      } else {
        return ''
      }
    },
    getRecordStatusText(val, isWholeState) {
      // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
      if (val === 0) {
        return isWholeState?'审核中':'待审批'
      } else if (val === 1) {
        return isWholeState?'审核中':'待审批'
      } else if (val === 2) {
        return '通过'
      } else if (val === 3) {
        return '驳回'
      } else if (val === 4) {
        return '已失效'
      } else {
        return ''
      }
    },
    recordEnd(val) {
      if (!val) {
        return false
      }
      if (val.approveStatus === 2 || val.approveStatus === 3 || val.approveStatus === 4) {
        return true
      } else {
        return false
      }
    },
    goEvectionApplyDetail() {
      if (this.$store.state.workflow === '1') {
        this.$router.push({
          name: 'admin-evection-detail',
          query: {
            evectionNo: this.detail.orderInterimResult.evectionNo,
            fromType: 5
          }

        })
      } else {
        // 1.我的申请 2.待我审批
        // /admin/my-apply-manage/apply/info?applyId&applyNo=1854441850372100096&appType=1&selectType=1&approveStatus=5&type=info&approveType=1&lastMenu=adminMyApply
        switch (this.approvalMethod) {
          case 1:
            this.$router.push({
              name: 'admin-my-apply-manage-apply-info',
              query: {
                applyId: parseInt(this.detail.applyId),
                approveType: this.detail.approveType,
                applyNo: this.detail.applyNo,
                appType: this.detail.approveType,
                type: 'info',
                selectType: 1,
                approveStatus: this.detail.approveStatus || 5,
                lastMenu: 'adminMyApply'
              }
            })
            break
          case 2:
            this.$router.push({
              name: 'admin-my-apply-manage-noApprove-detail',
              query: {
                applyId: parseInt(this.detail.applyId),
                approveType: this.detail.approveType,
                type: 'audit',
              }
            })
            break
        }
      }

    },
    //跳转至审批单详情
    goApprovalDetail() {
      if (this.$store.state.workflow === '1') {
        this.$router.push({
          name: 'admin-apply-detail-for-workflow',
          query: {
            applyNo: this.detail.applyNo,
            approveType: this.detail.approveType || 1,
            applyType: 1, // 1我的申请，2待我审批
          }
        })
      } else {
        this.$router.push({
          name: 'admin-my-apply-manage-noApprove-detail',
          query: {
            applyId: parseInt(this.detail.applicationId),
            approveType: 7,
            type: 'audit'
          }
        })
      }

    },
    toApplyDetail() {
      if (this.$store.state.workflow === '1') {
        this.$router.push({
          name: 'admin-apply-detail-for-workflow',
          query: {
            applyNo: this.detail.applyNo,
            approveType: 2,
            applyType: 1, // 1我的申请，2待我审批
          }
        })
      } else {
        this.$router.push({
          name: "admin-my-apply-manage-apply-approve-exceed",
          query: {
            applyId: this.detail.applicationId,
            type: "applyDetail",
            lastMenu: 'adminMyApply'
          }
        });
      }

    },
    to_payment(orderNo) {
      if (this.detail.evectionInfo.evectionType === 1) {
        this.$router.push({
          name: 'admin-personal-hotel-submit-order',
          query: {
            orderNo: orderNo,
          },
        });
      } else {
        this.$router.push({
          name: "admin-pay",
          query: {
            orderNos: orderNo,
            orderType: "3",
          },
        });
      }

    },
    cancel(orderNo) {
      this.$confirm("是否确认取消订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          req_cancel_order({ orderNo, cancelRemark: "" })
            .then((res) => {
              this.$message.success("取消订单成功");
              this.req_detail();
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    to_refund(val) {
      console.log(val)
      this.loading = true;
      let params = { orderNo: this.detail.order.orderNo, evectionNo: this.detail.order.evectionNo, cancelRemark: '' };
      this.$refs.aHotelRefund.clickUnsubscribe(params).then(res=>{
        console.log('1');
        this.loading = false;
        if (res) {
          this.req_detail();
        }
      }, error => {
        console.log('2');
        this.loading = false;
      }).catch(e=>{
        console.log('3');
        this.loading = false;
      });
      /*this.$router.push({
        name: "admin-hotel-order-refund",
        query: {
          orderNo
        }
      });*/
    },
    to_list () {
      this.$router.back();
    },
    // 订单日志
    getOrderLog() {
      this.$refs.orderLog.init({
        getDataSource: () => {
          this.loading = true
          // 调用获取订单日志的接口
          // return consumer_log_hotelList({
          //     orderNo: this.$route.query.id
          // }).then(res => {
          // 组装组件需要的list
          let res = {
            datas: [{"operator":"个人测试","operatorTime":"2021-12-06T15:54:09","logSource":"1","logContents":null},{"operator":"个人测试","operatorTime":"2021-12-06T15:55:01","logSource":"1","logContents":null},{"operator":"个人测试","operatorTime":"2021-12-06T15:55:57","logSource":"1","logContents":"创建订单成功，订单号：13877698977300"},{"operator":"个人测试","operatorTime":"2021-12-06T15:56:08","logSource":"1","logContents":"创建订单成功，订单号：13877698977300"},{"operator":"个人测试","operatorTime":"2021-12-06T16:02:58","logSource":"1","logContents":"调用胤之旅接口，取消订单成功"}]
          }
          const list = _.chain(res.datas)
              .map(function (m) {
                // 替换名字
                const o = {
                  operator: m.operator,
                  date: m.operatorTime,
                  type: m.logSource,
                  detail: m.logContents
                }
                return o
              }).value()
          const d = {
            entityList: list
          }
          return d || {}
          // }).catch(() => {
          //     return []
          // }).finally(() => {
          this.loading = false
          // })
        }
      })
    },
  },
  created() {},
  mounted() {

  },
  activated() {
    this.getCurUserInfo();
    this.req_detail();
    // this.getOrderLog();
    this.isShowBtns = this.$route.meta.data_for_back_layout && this.$route.meta.data_for_back_layout.layoutType === 'orderType';
  },
  deactivated() {
    clearInterval(this.paySetIntervalFun)
  },
  destroyed() {},
  watch: {},
  filters: {
    format_time(val) {
      return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
    },
    format_week(val) {
      if (val) {
        switch (moment(val).day()) {
          case 1:
            return "周一";
            break;
          case 2:
            return "周二";
            break;
          case 3:
            return "周三";
            break;
          case 4:
            return "周四";
            break;
          case 5:
            return "周五";
            break;
          case 6:
            return "周六";
            break;
          case 0:
            return "周日";
            break;
        }
      } else {
        return "";
      }
    },
    format_create(val) {
      switch (Number(val)) {
        case 1:
          return "个人创建";
          break;
        case 2:
          return "出差单创建";
          break;
      }
    },
    format_payType(val) {
      switch (Number(val)) {
        case 1:
          return "个人支付";
          break;
        case 2:
          return "企业支付";
          break;
      }
    },
    format_status(val) {
      switch (Number(val)) {
        case 0:
          return "待支付";
          break;
        case 1:
          return "支付中";
          break;
        case 2:
          return "已支付，待出房";
          break;
        case 4:
          return "已支付，待入住";
          break;
        case 6:
          return "已取消";
          break;
        case 7:
          return "取消中";
          break;
        case 8:
          return "取消失败";
          break;
        case 9:
          return "预下单";
          break;
        case 10:
          return "创建订单失败";
          break;
        case 11:
          return "已出房";
          break;
        case 12:
          return "已退款";
          break;
        case 13:
          return "退款失败";
          break;
        case 14:
          return "出房失败";
          break;
        case 15:
          return "退款中";
          break;
        case 18://新增
          return "超标审批申请中";
          break;
        case -1:
          return "已删除";
          break;
      }
    },
    format_sex(val) {
      switch (val) {
        case 1:
          return "成人";
          break;
        case 2:
          return "儿童";
          break;
      }
    },
    format_cardType(val) {
      switch (val) {
        case 1:
          return "身份证";
          break;
        case 2:
          return "护照";
          break;
        case 4:
          return "回乡证";
          break;
        case 5:
          return "台胞证";
          break;
        case 6:
          return "军官证";
          break;
        case 7:
          return "香港身份证";
          break;
        case 11:
          return "澳门身份证";
          break;
        case 10:
          return "其它";
          break;
      }
    },
    filterTime(val) {
      if (val) {
        // 转换为式分秒
        let h = parseInt(val / 60 / 60 % 24);
        h = h < 10 ? '0' + h : h;
        let m = parseInt(val / 60 % 60);
        m = m < 10 ? '0' + m : m;
        let s = parseInt(val % 60);
        s = s < 10 ? '0' + s : s;
        let text = `${m}分${s}秒`
        if (h !== '00') {
          text = h + '时' + text
        }
        return text
      } else {
        return '00分00秒'
      }
    },
  },
};
